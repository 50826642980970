import { useState, useEffect } from 'react'

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import { HeadersFunction } from '@remix-run/node'

import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useDehydratedState } from 'use-dehydrated-state'
import Analytics from './components/Analytics/Analytics'

import "./tailwind.css";

export const headers: HeadersFunction = () => ({
  'Access-Control-Allow-Origin': '*'
})

export function Layout({ children }: { children: React.ReactNode }) {
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    return setCurrentUrl(window.location.hostname)
  }, [])

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime:
              currentUrl.indexOf('localhost') > -1 ||
              currentUrl.indexOf('preview-') > -1
                ? 60 * 1000
                : 0
          }
        }
      })
  )

  const dehydratedState = useDehydratedState();

  if (process.env.NODE_ENV !== 'development' && (!currentUrl.startsWith('staging-') || !currentUrl.startsWith('preview-'))) {
    Analytics()
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel='icon'
          type='image/svg+xml'
          href='https://www.hull.ac.uk/site-elements/img/favicon/favicon.svg'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://www.hull.ac.uk/site-elements/img/favicon/favicon.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='https://www.hull.ac.uk/site-elements/img/favicon/apple-touch-icon.png'
        />
        <link
          rel='manifest'
          href='https://www.hull.ac.uk/site-elements/img/favicon/site.json'
        />
        <link
          rel='mask-icon'
          href='https://www.hull.ac.uk/site-elements/img/favicon/safari-pinned-tab.svg'
          color='#0e1647'
        />
        <link
          rel='shortcut icon'
          href='https://www.hull.ac.uk/site-elements/img/favicon/favicon.ico'
        />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta
          name='msapplication-config'
          content='https://www.hull.ac.uk/site-elements/img/favicon/browserconfig.xml'
        />
        
        <Meta />
        <Links />
          
      </head>
      <body suppressHydrationWarning={true}>
        {process.env.NODE_ENV !== 'development' &&
          (!currentUrl.startsWith('staging-') ||
            !currentUrl.startsWith('preview-')) && (
            <>
              <noscript>
                <iframe
                  src='https://www.googletagmanager.com/ns.html?id=GTM-P978LR'
                  title='Google Tag Manager'
                  height='0'
                  width='0'
                  style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
              </noscript>
            </>
          )}
        
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={dehydratedState}>
           {children}
          </HydrationBoundary>
          
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
